<template>
  <div class="Logout">
    "Logout"
  </div>
</template>

<script>
export default {
  created () {
    window.sessionStorage.clear();
    this.$router.push({name: 'Login'});
  }
}
</script>
